customElements.define(
    "top-search-items",
    class extends HTMLElement {
        constructor() {
            super();

            this.setSearchItem = this.setSearchItem.bind(this);
            this.addEventListener("click", this.setSearchItem);
            this.clear = this.clear.bind(this);
        }

        connectedCallback() {
            document.body.addEventListener(
                "top-search-items:clear",
                this.clear,
            );

            let defaultSelectedTopSearchItem =
                this.querySelector("button.selected");
            if (defaultSelectedTopSearchItem) {
                let form = this.closest("rm-search");
                this.dispatchEvents(form, defaultSelectedTopSearchItem);
            }
        }

        disconnectedCallback() {
            document.body.removeEventListener(
                "top-search-items:clear",
                this.clear,
            );
        }

        setSearchItem(event) {
            event.preventDefault();
            let form = this.closest("rm-search");
            let item = event.target.closest("button");
            let prev = this.querySelector("button.selected");

            if (form && item) {
                let action = prev == item ? "clear" : "set";

                if (prev) {
                    prev.classList.remove("selected");
                }

                if (item.dataset.resource != "link") {
                    item.classList.add("selected");
                }

                this.dispatchEvents(form, item, prev, action);
            }
        }

        dispatchEvents(form, item, prev = null, action = "set") {
            let dest = "";
            if (Object.keys(item.dataset).includes("resource")) {
                dest = "resource-suggest";
            }
            if (prev && item.dataset.resource == "link") {
                // when selecting a Link, it should always and only clear the
                // resource-suggest
                form.dispatchEvent(
                    new CustomEvent("rm-search:input-update", {
                        detail: {
                            nextEventName: `${dest}:clear`,
                            target: prev,
                            origin: "top-search-items",
                        },
                    }),
                );
            }

            form.dispatchEvent(
                new CustomEvent("rm-search:input-update", {
                    detail: {
                        nextEventName: `${dest}:set`,
                        target: item,
                        origin: "top-search-items",
                        searchEvent: {
                            type: "resource-suggest__selection",
                            data: {
                                origin: "top-search-items",
                            },
                        },
                    },
                }),
            );

            if (item.dataset.resource != "provider") {
                form.dispatchEvent(
                    new CustomEvent("rm-search:input-update", {
                        detail: {
                            name: "resource-suggest",
                            set: false,
                        },
                    }),
                );
            }
        }

        clear(event) {
            let selected = this.querySelector("button.selected");
            if (selected) {
                selected.classList.remove("selected");
            }
        }
    },
);
